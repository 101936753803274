import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const CenteredBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  flex: "0 0 auto",
});

export default CenteredBox;
