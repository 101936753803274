import CenteredBox from "@components/common/CenteredBox";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import RedSiftLoadingIndicator from "../components/RedSiftLoadingIndicator";

const Home: NextPage = () => {
  const router = useRouter();
  useEffect(() => {
    // Redirect users to the login page
    router.replace("/login");
  }, [router]);

  return (
    <CenteredBox>
      <RedSiftLoadingIndicator />
    </CenteredBox>
  );
};

export default Home;
